import React, { useState, useEffect } from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { format } from "date-fns";
import { useNavigate, useLocation } from "react-router-dom";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import "./Debt.css";
import api from "../../api/tokenCode";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess, notifyError } from "../../constant/toast";
import "react-datepicker/dist/react-datepicker.css";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const checkboxes = [
  { id: 1, title: "Vehicle" },
  { id: 2, title: "Student Loan" },
  { id: 3, title: "Mortgage" },
  { id: 4, title: "Personal Loan" },
  { id: 5, title: "Credit Card" },
];

const InputField = ({ maxLength,placeholder, register, error, type = "text", style }) => (
  <div className="input-field-container">
    <input
      type={type === "number" ? "text" : type} // Use "text" for number input to handle cursor position
      className="input-field"
      maxLength={maxLength}
      onInput={(e) => {
        if (type === "text") {
           // Allow only letters and spaces
          // inputValue = inputValue.length > 1 ? inputValue.trimStart() : inputValue; // Trim leading spaces only if more than one character
          // e.target.value = inputValue.charAt(0).toUpperCase() + inputValue.slice(1); // Capitalize the first character
        } else if (type === "number") {
          let inputValue = e.target.value;

          // Preserve cursor position
          const cursorPosition = e.target.selectionStart;

          // Remove non-numeric characters except the first decimal point
          inputValue = inputValue.replace(/[^0-9.]/g, ""); // Allow only numbers and decimal

          // Ensure only one decimal point is allowed
          const hasDecimal = inputValue.includes(".");
          if (hasDecimal && inputValue.split(".").length > 2) {
            // Remove any additional decimals beyond the first
            inputValue = inputValue.replace(/(\..*?)\./g, "$1");
          }

          // Update the input value and reset cursor
          e.target.value = inputValue;
          e.target.setSelectionRange(cursorPosition, cursorPosition);
        }
      }}
      onBlur={(e) => {
        if (type === "number") {
          // Ensure value is parsed as a float or empty if invalid
          e.target.value = e.target.value ? parseFloat(e.target.value) || "" : "";
        }
      }}
      placeholder={placeholder}
      {...register}
      style={style}
    />

    {error && <p className="error-message">{error.message}</p>}
  </div>
);


const Debt = ({ data }) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [loanDetails, setLoanDetails] = useState([]);
  const [savedDebt, setSavedDebt] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const [debtData, setDebtData] = useState(data || location.state?.debtData);
  const clientData = JSON.parse(localStorage.getItem("client"));
  const userId = clientData?.user_id || "";

  // State for date
  const [startDate, setStartDate] = useState(null);
  const [open, setOpen] = useState(false);
  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = format(date, "MM/dd/yyyy"); // Format to MM/DD/YYYY
      setStartDate(formattedDate); // Set in local state
      setValue("startDate", formattedDate); // Set in form state
    }
  };

  const handleCheckboxChange = (id) => {
    // Uncheck all other checkboxes
    checkboxes.forEach(({ id: checkboxId }) => {
      if (checkboxId !== id) {
        setValue(`checkboxes.${checkboxId}`, false);
      }
    });
  };

  const handleRowClick = () => {
    navigate(`/details/${clientData.id}`);
  };

  const onSubmit = async (formData) => {
    const selectedType =
      checkboxes.find(({ id }) => formData.checkboxes?.[id])?.title ||
      (formData.other ? "Other" : "");

    const newLoanDetail = {
      name: formData.name || "",
      loanAmount: formData.loanAmount || "",
      apr: formData.apr || "",
      currentPayment: formData.currentPayment || "",
      minimumPayment: formData.minimumPayment || "",
      startYear: formData.startYear || "",
      term: Number(formData.term) || "", // Convert to ISO string for API
      type: selectedType,
      otherStr: formData.other ? formData.otherDescription || "None" : "None",
      clientId: userId,
    };

    const updatedLoanDetails = [newLoanDetail];
    setLoanDetails(updatedLoanDetails);

    try {
      const response = await api.post("/debt/createDebt", {
        loan: updatedLoanDetails,
      });

      setSavedDebt(response.data);
      reset();
      notifySuccess("Debt Created Successfully!");
      setTimeout(() => {
        navigate(`/details/${clientData.id}`);
      }, 2000);
    } catch (error) {
      notifyError(`${error.response?.data?.message}`);
      console.error("Form submission error:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSidenav data={savedDebt} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          overflowY: "auto",
        }}
      >
        <div className="main-container">
          <div>
            <h1 className="debt-name">DEBT</h1>
            <button
              type="button"
              className="back-button-debts"
              onClick={handleRowClick}
            >
              BACK
            </button>
          </div>

          <div className="container">
            <p className="">LOAN</p>
          </div>
          <hr className="hr-tag" />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="checkbox-lists">
              {checkboxes.map(({ id, title }) => (
                <label key={id} className="checkbox-item">
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    {...register(`checkboxes.${id}`)}
                    onChange={() => handleCheckboxChange(id)}
                  />
                  {title}
                </label>
              ))}
              <label className="checkbox-item">
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  {...register("other")}
                />
                Other:
                <Controller
                  name="otherDescription"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      placeholder="Describe"
                      className="input-field"
                      {...field}
                    />
                  )}
                />
              </label>
            </div>

            <div className="input-container">
              <InputField
                placeholder="Lender"
                className="lenderFields"
                register={register("name", {
                  required: "* Lender is required",
                })}
                error={errors.name}
              />
              <InputField
                placeholder="Loan Amount"
                type="number" // Numeric input
                register={register("loanAmount", {
                  required: "* Loan Amt is required",
                })}
                error={errors.loanAmount}
              />
              <InputField
                placeholder="APR %"
                type="number" // Numeric input
                register={register("apr", { required: "* APR is required" })}
                error={errors.apr}
              />
              <InputField
                placeholder="Current Pymt"
                type="number" // Numeric input
                register={register("currentPayment", {
                  required: "* Curnt Pymt is required",
                })}
                error={errors.currentPayment}
              />
              {/* <InputField
                placeholder="Min Pymt"
                type="number" // Numeric input
                register={register("minimumPayment", {
                  required: "* Min Pymt is required",
                })}
                error={errors.minimumPayment}
              /> */}  

              <InputField
                placeholder="Years"
                className="lenderFields"
                register={register("term", {
                  required: "* End Year is required",
                })}
                error={errors.term}
                maxLength={4}
              />

              <div className="debt-add-button">
                <button type="submit" className="add-button">
                  ADD
                </button>
              </div>
            </div>
          </form>
        </div>
      </Box>
    </Box>
  );
};

export default Debt;
